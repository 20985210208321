import { saveState, getStateItem } from "../../utils/localStorageHelper";
import i18n from "@/plugins/i18n.js";
export const state = {
  volume: 1,
  device: "desktop",
  homepageToast: null
};

export const getters = {
  getVolume(state) {
    return state.volume;
  },
  isDesktop(state) {
    return state.device === "desktop";
  },
  getHomepageToast(state) {
    return state.homepageToast;
  },
  getToastPosition() {
    return state.device === "desktop" ? "bottom-right" : "bottom";
  }
};

export const mutations = {
  UPDATE_VOLUME(state, volume) {
    state.volume = volume;
  },
  UPDATE_DEVICE_TYPE(state, device) {
    state.device = device;
  },
  UPDATE_HOMEPAGE_TOAST(state, homepageToast) {
    state.homepageToast = homepageToast;
    saveState("homepageToast", state.homepageToast);
  }
};

export const actions = {
  init({ commit }) {
    if (getStateItem("locale") !== null) {
      i18n.locale = getStateItem("locale");
    }
    if (getStateItem("homepageToast") !== null) {
      commit("UPDATE_HOMEPAGE_TOAST", getStateItem("homepageToast"));
    }
  },
  // eslint-disable-next-line no-empty-pattern
  saveUserLocale({}, locale) {
    saveState("locale", locale);
  },
  updateVolume({ commit }, value) {
    commit("UPDATE_VOLUME", value);
  },
  updateDeviceType({ commit }, value) {
    commit("UPDATE_DEVICE_TYPE", value);
  },
  setHomepageToast({ commit }, homepageToast) {
    console.log("setHomepageToast");
    commit("UPDATE_HOMEPAGE_TOAST", homepageToast);
  },
  resetHomepageToast({ commit }) {
    console.log("resetHomepageToast");
    commit("UPDATE_HOMEPAGE_TOAST", null);
  }
};
