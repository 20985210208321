import { Hub } from "aws-amplify";
import store from "@/store";
import i18n from "@/plugins/i18n.js";

const authenticationMethod = data =>
  data?.payload?.data?.signInUserSession?.idToken?.payload?.identities?.[0]
    .providerType || "email_password";

const signInIsFirstEverSignIn = data =>
  parseInt(
    data?.payload?.data?.signInUserSession?.idToken?.payload?.[
      "profile:loginCount"
    ]
  ) === 1;

const analyticsUserId = data =>
  data?.payload?.data?.signInUserSession?.idToken?.payload?.[
    "profile:analyticsUserId"
  ];

const parseCognitoMessage = data => {
  if (!data?.payload?.data?.message) {
    return i18n.t(`message.cognito.login.login-failed`);
  }
  const cognitoMessage = String(data.payload.data.message).replaceAll("+", " ");
  const translationKey = cognitoMessage
    .toLowerCase()
    .replaceAll(" ", "-")
    .replaceAll(/[^a-z0-9_-]/g, "");
  if (i18n.te(`message.cognito.login.${translationKey}`)) {
    return i18n.t(`message.cognito.login.${translationKey}`);
  } else {
    return cognitoMessage;
  }
};

export default {
  install(Vue) {
    Hub.listen("auth", data => {
      switch (data.payload.event) {
        case "signIn":
          if (signInIsFirstEverSignIn(data)) {
            Vue.prototype.$analytics.signUp(authenticationMethod(data));
          }
          Vue.prototype.$analytics.updateAnalyticsUserId(analyticsUserId(data));
          Vue.prototype.$analytics.login(authenticationMethod(data));
          break;
        // case 'signUp':
        //   break;
        case "signOut":
        case "oAuthSignOut":
          Vue.prototype.$analytics.logout(); // authenticationMethod is inaccessible here
          break;
        case "signIn_failure":
          if (data?.payload?.message == "The OAuth response flow failed") {
            // only show toast errors for OAuth login failures
            store.dispatch("general/setHomepageToast", {
              type: "error",
              message: parseCognitoMessage(data),
              duration: 0,
              position: "top"
            });
            if (data?.payload?.data?.message == "invalid_grant") {
              store.dispatch("auth/unsetLoggedUser");
            }
          }
          Vue.prototype.$analytics.loginFailed(); // authenticationMethod is inaccessible here
          break;
        // case 'tokenRefresh':
        //   break;
        // case 'tokenRefresh_failure':
        //   break;
        // case 'configured':
        //   break;
        // default:
        //   break;
      }
    });
  }
};
