// enable a disabled gtag using...
// window['ga-disable-' + gtagMeasurementId] = false
// bootstrapping: https://matteo-gabriele.gitbook.io/vue-gtag/custom-installation

export default class VueIubendaCookieConsent {
  static install(_Vue, options) {
    window._iub = window._iub || [];
    window._iub.csConfiguration = {
      ccpaAcknowledgeOnDisplay: false,
      whitelabel: false,
      lang: options.lang,
      floatingPreferencesButtonDisplay: false,
      siteId: options.siteId,
      enableCcpa: true,
      countryDetection: true,
      cookiePolicyId: options.cookiePolicyId,
      privacyPolicyUrl: "https://www.gameofourlives.org/docs/privacy-policy",
      cookiePolicyUrl: "https://www.gameofourlives.org/docs/cookie-policy",
      invalidateConsentWithoutLog: true,
      banner: {
        closeButtonRejects: true,
        acceptButtonDisplay: true,
        customizeButtonDisplay: true,
        closeButtonDisplay: false,
        position: "bottom",
        // acceptButtonCaption: "Accept",
        acceptButtonColor: "#0073CE",
        acceptButtonCaptionColor: "white",
        customizeButtonColor: "#DADADA",
        // customizeButtonCaption: "Learn more and customize",
        customizeButtonCaptionColor: "#4D4D4D",
        // rejectButtonCaption: "Reject",
        rejectButtonColor: "#DADADA",
        rejectButtonCaptionColor: "#4D4D4D",
        rejectButtonDisplay: true,
        textColor: "black",
        backgroundColor: "white",
        backgroundOverlay: true,
        listPurposes: true,
        ...options
      },
      callback: {
        ...options.callback
        // onConsentFirstGiven: () => {
        //   console.log("iubenda onConsentFirstGiven");
        // },
        // onConsentFirstRejected: () => {
        //   console.log("iubenda onConsentFirstRejected");
        // },
        // onConsentRejected: () => {
        //   console.log("iubenda onConsentRejected");
        // },
      }
    };
    const stub = document.createElement("script");
    stub.setAttribute("type", "text/javascript");
    stub.setAttribute("src", "//cdn.iubenda.com/cs/ccpa/stub.js");
    const iubenda = document.createElement("script");
    iubenda.setAttribute("type", "text/javascript");
    iubenda.setAttribute("src", "//cdn.iubenda.com/cs/iubenda_cs.js");
    iubenda.setAttribute("charset", "UTF-8");
    iubenda.setAttribute("async", true);
    document.head.appendChild(stub);
    document.head.appendChild(iubenda);
  }
}
