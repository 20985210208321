const redirects = [
  {
    path: "/thriveafrica",
    redirect: () => {
      return { path: "/team/thrive-africa" };
    }
  },
  {
    path: "/g-justice",
    redirect: () => {
      return { path: "/team/g-justice" };
    }
  },
  {
    path: "/thierry",
    redirect: () => {
      return { path: "/team/highlight-the-hate" };
    }
  },
  {
    path: "/humaneukraine",
    redirect: () => {
      return { path: "/team/humane-ukraine" };
    }
  }
];

export default redirects;
