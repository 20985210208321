import AmazonCognitoIdentity, { Auth } from "@aws-amplify/auth";
import { saveState, getStateItem } from "../../utils/localStorageHelper";
import router from "@/router";
import gql from "graphql-tag";
import { createProvider } from "@/vue-apollo";
import {
  isUserFederated,
  standardizeBirthDate
} from "../../utils/basicHelpers";

const apollo = createProvider();

export const state = {
  loggedUser: null,
  isFinishedLoadingUser: false,
  newUser: {
    stepCompleted: 0,
    email: null,
    name: null,
    familyName: null,
    dateOfBirth: null,
    created: false,
    contactPreferences: ""
  }
};

export const getters = {
  currentUser(state) {
    return state.loggedUser;
  },
  isCurrentUserFederated(state) {
    return state.loggedUser?.isFederated;
  },
  newUser(state) {
    return state.newUser;
  },
  //this function should find the actual step for the user creation
  getNewUserValidLocation(state) {
    if (state.newUser.email == null) {
      return "SignUpEmailForm";
    }
    if (state.newUser.dateOfBirth == null) {
      return "SignUpBirthDateForm";
    }
    if (state.newUser.name == null) {
      return "SignUpNameForm";
    }
    if (state.newUser.familyName == null) {
      return "SignUpNameForm";
    }
    if (state.newUser.created !== true) {
      return "CreatePassword";
    }
    return true;
  },
  getNewUserContactPreferences(state) {
    return state.contactPreferences;
  },
  getNewUser(state) {
    return state.newUser;
  },
  getNewUserName(state) {
    return state.newUser?.name;
  },
  getNewUserFamilyName(state) {
    return state.newUser?.familyName;
  },
  getNewUserEmail(state) {
    return state.newUser?.email;
  },
  getNewUserDateOfBirth(state) {
    return state.newUser?.dateOfBirth;
  },
  getNewUserStep(state) {
    return state.newUser.stepCompleted;
  },
  getNewUserCreatedConfirmed(state) {
    return state.newUser.created;
  },
  isLogged(state) {
    return !(state.loggedUser === null);
  },
  isFinishedLoadingUser() {
    return state.isFinishedLoadingUser;
  }
};

export const mutations = {
  UPDATE_LOGGED_USER(state, newUser) {
    state.loggedUser = newUser;
    state.isFinishedLoadingUser = true;
    saveState("loggedUser", newUser);
    return;
  },
  UPDATE_NEW_USER_NAME(state, name) {
    state.newUser.name = name;
    saveState("newUser", state.newUser);
  },
  UPDATE_NEW_USER_FAMILY_NAME(state, familyName) {
    state.newUser.familyName = familyName;
    saveState("newUser", state.newUser);
  },
  UPDATE_NEW_USER_DATE_OF_BIRTH(state, dateOfBirth) {
    state.newUser.dateOfBirth = dateOfBirth;
    saveState("newUser", state.newUser);
  },
  UPDATE_NEW_USER_EMAIL(state, email) {
    state.newUser.email = email;
    saveState("newUser", state.newUser);
  },
  UPDATE_NEW_USER_CREATED(state, newStatus) {
    state.newUser.created = newStatus;
    saveState("newUser", state.newUser);
  },
  UPDATE_NEW_USER_STEP_COMPLETED(state, step) {
    state.newUser.stepCompleted = step;
    saveState("newUser", state.newUser);
  },
  SET_NEW_USER(state, user) {
    state.newUser = user;
  },
  UPDATE_CONTACT_PREFERENCES(state, contactPreferences) {
    state.newUser.contactPreferences = contactPreferences;
  }
};

export const actions = {
  async init({ commit, dispatch }) {
    if (getStateItem("newUser") !== null) {
      commit("SET_NEW_USER", getStateItem("newUser"));
    }
    // await dispatch("refreshAuthenticatedUser");
    let user = await dispatch("refreshAuthenticatedUser");
    if (user && user.status == "require_birthdate") {
      router.push({ name: "FederateBirthDate" });
    }
  },
  async login({ commit, dispatch }, { email, password }) {
    return await Auth.signIn(email.toLowerCase(), password)
      .then(async () => {
        const currentSession = await Auth.currentSession();
        dispatch("setAuthenticatedUser", currentSession);
        return true;
      })
      .catch(e => {
        commit("UPDATE_LOGGED_USER", null);
        return e;
      });
  },
  logout({ commit }) {
    Auth.signOut().then(() => {
      commit("UPDATE_LOGGED_USER", null);
      router.push({ name: "HomeIn" });
    });
    return;
  },
  unsetLoggedUser({ commit }) {
    commit("UPDATE_LOGGED_USER", null);
  },
  setNewUserPassword({ commit }, password) {
    commit("UPDATE_NEW_USER_PASSWORD", password);
  },
  setNewUserName({ commit }, name) {
    commit("UPDATE_NEW_USER_NAME", name);
  },
  setNewUserFamilyName({ commit }, familyName) {
    commit("UPDATE_NEW_USER_FAMILY_NAME", familyName);
  },
  setNewUserDateOfBirth({ commit }, dateOfBirth) {
    commit("UPDATE_NEW_USER_DATE_OF_BIRTH", dateOfBirth);
  },
  setNewUserEmail({ commit }, email) {
    commit("UPDATE_NEW_USER_EMAIL", email);
  },
  setContactPreferences({ commit }, contactPreferences) {
    commit("UPDATE_CONTACT_PREFERENCES", contactPreferences);
  },
  setNewUserStepCompleted({ commit, state }, step) {
    if (state.newUser.stepCompleted < step) {
      commit("UPDATE_NEW_USER_STEP_COMPLETED", step);
    }
  },
  async createUserAccount({ commit, dispatch, state }, password) {
    const birthdate = standardizeBirthDate(state.newUser.dateOfBirth);

    try {
      await Auth.signUp({
        username: state.newUser.email.toLowerCase(),
        password: password,
        attributes: {
          email: state.newUser.email.toLowerCase(),
          given_name: state.newUser.name,
          family_name: state.newUser.familyName,
          birthdate,
          "custom:contactPreferences": state.newUser.contactPreferences
        }
      });
      await dispatch("resetNewUser");
      commit("UPDATE_NEW_USER_CREATED", true);
      return true;
    } catch (err) {
      console.log("error signing in", err);
      return err;
    }
  },
  resetNewUser({ commit }) {
    const user = {
      stepCompleted: 0,
      email: null,
      name: null,
      familyName: null,
      dateOfBirth: null,
      created: false
    };
    commit("SET_NEW_USER", user);
    saveState("newUser", user);
  },
  async refreshAuthenticatedUser({ commit, dispatch }) {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const currentSession = await Auth.currentSession();
      const freshAuthenticatedUser = await new Promise((resolve, reject) => {
        cognitoUser.refreshSession(
          currentSession.refreshToken,
          (err, session) => {
            if (session) {
              resolve(session);
            }
            if (err) {
              reject(err);
            }
          }
        );
      });
      if (freshAuthenticatedUser.idToken) {
        return await dispatch("setAuthenticatedUser", freshAuthenticatedUser);
      } else {
        //error on token refresh
        cognitoUser.setSignInUserSession(
          new AmazonCognitoIdentity.CognitoUserSession({
            IdToken: new AmazonCognitoIdentity.CognitoIdToken({ IdToken: "" }),
            AccessToken: new AmazonCognitoIdentity.CognitoAccessToken({
              AccessToken: ""
            }),
            RefreshToken: new AmazonCognitoIdentity.CognitoRefreshToken({
              RefreshToken: ""
            })
          })
        );
        dispatch("logout");
      }
    } catch (err) {
      commit("UPDATE_LOGGED_USER", null);
      console.log("not logged user found - on session refresh");
      return null;
    }
  },
  setAuthenticatedUser({ commit }, freshAuthenticatedUser) {
    const attributes = {
      screenName: freshAuthenticatedUser.idToken.payload["profile:screenName"],
      displayName:
        freshAuthenticatedUser.idToken.payload["profile:displayName"],
      profileImageUrl:
        freshAuthenticatedUser.idToken.payload["profile:profileImageUrl"],
      birthdate:
        freshAuthenticatedUser.idToken.payload["profile:birthdate"] || null,
      analyticsUserId:
        freshAuthenticatedUser.idToken.payload["profile:analyticsUserId"]
    };
    const user = {
      id: freshAuthenticatedUser.idToken.payload["cognito:username"],
      attributes: attributes,
      groups: freshAuthenticatedUser.idToken.payload["cognito:groups"] || [],
      isFederated: isUserFederated(
        freshAuthenticatedUser.idToken.payload["cognito:username"]
      )
    };
    commit("UPDATE_LOGGED_USER", user);
    return user;
  },
  async updateUserDateOfBirth({ dispatch }, date) {
    await apollo.defaultClient
      .mutate({
        mutation: gql`
          mutation($newProfile: ProfileInput!) {
            editMyProfile(newProfile: $newProfile) {
              id
            }
          }
        `,
        variables: {
          newProfile: {
            birthdate: date
          }
        },
        update(data, data2) {
          console.log(data, data2);
          return true;
        }
      })
      .catch(() => {
        return false;
      });

    return await dispatch("refreshAuthenticatedUser");
  }
};
