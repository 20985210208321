<template>
  <div id="layout" class="h-full block scrollbar-hidden">
    <div id="layout-content" class="flex h-full w-full flex-col mx-auto">
      <MiniMenu
        v-if="showMenu"
        class="w-full"
        :fullScreen="fullScreen"
        :class="{ 'hidden md:block': $route.meta.hideMenuMobile }"
      />
      <div class="flex flex-col h-full">
        <div
          v-if="isFinishedLoadingUser && !isLogged && showMenu"
          class="min-w-full p-4 sticky top-0 bg-gray-50 z-50 md:hidden"
          :class="{ 'md:hidden': $route.meta.hideMenuMobile }"
        >
          <LoginShortCuts id="shortcuts" class="mx-auto" />
        </div>
        <div
          class="w-full flex-grow mx-auto md:pb-0"
          :class="{ 'md:px-4 max-w-desktop': !fullScreen }"
        >
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MiniMenu from "@/components/shared/MiniMenu.vue";
import LoginShortCuts from "@/components/shared/LoginShortCuts.vue";
import { mapGetters } from "vuex";

export default {
  name: "LayoutLoggedEmpty",
  components: {
    MiniMenu,
    LoginShortCuts
  },
  props: {
    fullScreen: {
      type: Boolean,
      required: false,
      default() {
        return false;
      }
    },
    showMenu: {
      type: Boolean,
      required: false,
      default() {
        return true;
      }
    }
  },
  computed: {
    ...mapGetters("auth", ["isLogged", "isFinishedLoadingUser"])
  }
};
</script>
<style lang="postcss">
#shortcuts {
  max-width: 354px;
}
</style>
