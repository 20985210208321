import {
  isValidDate,
  isValidContactPreferencesAttribute
} from "@/utils/basicHelpers";
import { jwtTokenPayload } from "@/utils/cognitoAuthToken";

// used to check the profile completion status of authenticated users, and redirect them to the update-profile view
export async function checkForIncompleteProfile({ next, to, halt }) {
  let userAttributes = await jwtTokenPayload();
  if (userAttributes) {
    if (
      !isValidDate(userAttributes["profile:birthdate"]) ||
      !isValidContactPreferencesAttribute(
        userAttributes["profile:contactPreferences"]
      )
    ) {
      return halt({
        name: "IncompleteProfile",
        params: {
          redirect: to.path
        }
      });
    }
  }
  return next();
}
