/* eslint-disable no-console */

import { register } from "register-service-worker";
console.log("working on mode: ", process.env.NODE_ENV);

if (
  process.env.NODE_ENV === "production" &&
  process.env.VUE_APP_SERVICE_WORKER === "enabled"
) {
  register(`${process.env.VUE_APP_WEB_URL}/service-worker.js`, {
    ready() {
      log(
        "App is being served from cache by a service worker.\n" +
          "For more details, visit https://goo.gl/AFskqB"
      );
    },
    registered() {
      log("Service worker has been registered.");
    },
    cached() {
      log("Content has been cached for offline use.");
    },
    updatefound() {
      log("New content is downloading.");
    },
    updated() {
      log("New content is available; please refresh.");
    },
    offline() {
      log("No internet connection found. App is running in offline mode.");
    },
    error(error) {
      log("Error during service worker registration:", error);
    }
  });
} else if (navigator.serviceWorker) {
  navigator.serviceWorker.getRegistrations().then(function(registrations) {
    if (registrations.length === 0) {
      log("No service workers found");
    }
    for (let registration of registrations) {
      log("Unregistering service worker:");
      log({ registration });
      registration.unregister();
    }
  });
}
const showLog = true;

function log(message) {
  if (showLog) {
    console.log(message);
  }
}
