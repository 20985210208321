class Analytics {
  constructor(vue_context) {
    if (vue_context.prototype.$gtag) {
      this.$gtag = vue_context.prototype.$gtag;
    }
    this.analyticsUserId = null;
  }

  event(name, data) {
    const payload = { ...data, analyticsUserId: this.analyticsUserId };
    console.log("$analytics:", name, payload);
    if (this.$gtag) {
      this.$gtag.event(name, payload);
    }
    if (process.env.VUE_APP_GTM_CODE) {
      window.dataLayer.push({
        event: name,
        data: payload
      });
    }
  }

  signUp(method) {
    this.event("sign_up", { method });
  }
  updateAnalyticsUserId(analyticsUserId) {
    this.analyticsUserId = analyticsUserId;
    this.event("user_id", {
      user_id: this.analyticsUserId
    });
  }
  login(method) {
    this.event("login", { method });
  }
  logout() {
    this.event("logout");
    this.updateAnalyticsUserId(null);
  }
  loginFailed() {
    this.event("login_failed");
  }
  joinTeam(team) {
    this.event("join_team", {
      team_id: team.id,
      team_slug: team.slug
    });
  }
  leaveTeam(team) {
    this.event("leave_team", {
      team_id: team.id,
      team_slug: team.slug
    });
  }
  followProfile(profile) {
    this.event("follow_profile", {
      profile_id: profile.id,
      profile_screen_name: profile.screenName
    });
  }
  unfollowProfile(profile) {
    this.event("leave_profile", {
      profile_id: profile.id,
      profile_screen_name: profile.screenName
    });
  }
  startTask(task) {
    this.taskEvent("start_task", task);
  }
  completeTask(task) {
    this.taskEvent("complete_task", task);
  }
  taskEvent(eventName, task) {
    const data = {
      task_id: task.id,
      task_title: task.title,
      task_type: task.type
    };
    if (task.tweetTemplate) {
      data.task_tweet_template = task.tweetTemplate;
    }
    this.event(eventName, data);
  }
  page_view(data) {
    this.event("page_view", data);
  }
  clientAppVersion(version) {
    this.event("client_app_version", { clientAppVersion: version });
  }
  serverAppVersion(version) {
    this.event("server_app_version", { serverAppVersion: version });
  }
  afterRouteChange(to) {
    let page_title = to.name;
    const accessor = to.meta?.page_view?.page_title_modifier;
    // accessor can be null, a string, or a function
    // if null, the fallbackString is wrapped in a function and returned
    // if a string, the string is wrapped in a function and returned
    // if a function, the function is returned
    if (accessor) {
      if (typeof accessor === "function") {
        page_title = `${page_title}-${accessor(to)}`;
      } else if (typeof accessor === "string" || accessor instanceof String) {
        page_title = `${page_title}-${accessor}`;
      }
    }
    this.page_view({ page_title: page_title });
  }
  /**
   * @param {String} type type of share options: profile | team | captain-profile
   * @param {*} data it can contain a profile | team | captain-profile
   * @param {String} destination where it was shared to native | copy-clipboard | twitter | facebook
   */
  sharedToExternal(type, data, destination) {
    let shareData;
    try {
      switch (type) {
        case "profile":
        case "captain-profile":
          try {
            const { profile } = data;
            if (!profile.id || !profile.screenName) throw "error";
            shareData = {
              shared_page: type,
              profile_id: profile.id,
              profile_screen_name: profile.screenName
            };
          } catch {
            throw "error on data provided for profile";
          }
          break;
        case "team":
          try {
            const { team } = data;
            if (!team.slug) throw "error";
            shareData = {
              shared_page: type,
              team_slug: team.slug
            };
          } catch {
            throw "error on data provided for team";
          }
          break;
        case "task-completed":
          try {
            const { task } = data;
            if (!task.teamSlug) throw "task-completed: no teamSlug provided";
            if (!task.taskId) throw "task-completed: no taskId";
            shareData = {
              shared_page: type,
              team_slug: task.teamSlug,
              task_id: task.taskId
            };
          } catch {
            throw "error on data provided for task-completed";
          }
          break;
        default:
          throw "invalid type of sharing";
      }
      this.event("external_share", {
        ...shareData,
        destination
      });
    } catch (e) {
      console.error(`Error on event: ${e}`);
    }
  }
  sendSupportRequest(fields) {
    this.event("support_request", {
      request_type: fields.requestType,
      request_url: fields.requestUrl
    });
  }
}

export default {
  install(Vue) {
    Vue.prototype.$analytics = new Analytics(Vue);
  }
};
