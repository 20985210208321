<template>
  <div
    class="logo-header relative w-full flex flex-row justify-center mt-14 mb-6"
  >
    <BackButton
      type="dark"
      v-if="showBackButton"
      @click="$router.go(-1)"
      class="absolute left-0"
    />
    <slot />
    <Logo v-if="showLogo" :color="colorLogo" @click="goHome()" />
  </div>
</template>
<script>
import BackButton from "@/components/shared/BackButton.vue";
import Logo from "@/components/Logo.vue";

export default {
  name: "LogoHeader",
  components: {
    Logo,
    BackButton
  },
  props: {
    colorLogo: {
      type: String,
      required: false,
      default: "pink"
    },
    showLogo: {
      type: Boolean,
      required: false,
      default: true
    },
    showBackButton: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  methods: {
    goHome() {
      this.$router.push({ name: "HomeIn" }).catch(() => {});
    }
  }
};
</script>
