<template>
  <component
    :is="$route.meta.layout || layout"
    :fullScreen="$route.meta.layoutFull || false"
    :showMenu="$route.meta.showMenu"
  >
    <router-view />
  </component>
</template>

<script>
import { GET_APP_VERSION } from "@/graphql/queries/appVersion/appVersionQueries.js";
import { clientVersion } from "@/utils/clientVersion.js";

export default {
  name: "App",
  data() {
    return {
      layout: "LayoutEmpty"
    };
  },
  created: () => {
    console.log(`client version: ${clientVersion()}`);
  },
  mounted() {
    this.updateWindowSize();
    window.addEventListener("resize", () => {
      this.updateWindowSize();
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", () => {
      this.updateWindowSize();
    });
  },
  apollo: {
    serverVersion: {
      query: GET_APP_VERSION,
      update(data) {
        console.log(`server version: ${data.appVersion}`);
        this.$analytics.serverAppVersion(data.appVersion);
      },
      variables() {
        return {
          clientVersion: clientVersion()
        };
      },
      fetchPolicy: "no-cache"
    }
  },
  computed: {
    isDesktop() {
      return this.$store.getters["general/isDesktop"];
    }
  },
  methods: {
    updateWindowSize() {
      const isDesktop = window.innerWidth > 768 ? true : false;
      if (this.isDesktop !== isDesktop) {
        this.$store.dispatch(
          "general/updateDeviceType",
          isDesktop ? "desktop" : "mobile"
        );
      }
    }
  }
};
</script>
<style>
/* Enter and leave animations can use different */

/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 4.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(200px);
  opacity: 0;
}
.kentico-content a {
  text-decoration: underline;
  text-decoration-color: #4a92b3;
  color: #4a92b3;
}
</style>
