import Vue from "vue";
import VueApollo from "vue-apollo";
import {
  createApolloClient,
  restartWebsockets
} from "vue-cli-plugin-apollo/graphql-client";
import { Auth } from "@aws-amplify/auth";
import apolloErrorHandling from "@/utils/apolloErrorHandling.js";

import { InMemoryCache } from "apollo-cache-inmemory";
import { IntrospectionFragmentMatcher } from "apollo-cache-inmemory";
import introspectionQueryResultData from "./fragmentTypes.json";
const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
});

// Install the vue plugin
Vue.use(VueApollo);
// Name of the localStorage item
const AUTH_TOKEN = "apollo-token";

// const token = localStorage.getItem(AUTH_TOKEN);
// const header = {
//   Authorization: token,
//   host: process.env.VUE_APP_GRAPHQL_ENDPOINT_BASE
// };
//took token from appsync
// const header =
//   "eyJBdXRob3JpemF0aW9uIjoiZXlKcmFXUWlPaUpaU1RKMFJFa3lSRFJzUzNaR05YWjVZVWhJWTA5MFFVYzFOR1JEWEM4NWVrOUhkVTl4Y0ZoWFEyMVhORDBpTENKaGJHY2lPaUpTVXpJMU5pSjkuZXlKemRXSWlPaUkyTVRZd016RmxOUzAzWlRZMkxUUmlORGd0T1RVMU9TMWhaR1JoTVdZME5XSmlNakVpTENKaGRXUWlPaUkzTUdOemFEaGxPVzFpTVd4cGJYWXpOMlZ5T1doMmEyWTVNU0lzSW1WdFlXbHNYM1psY21sbWFXVmtJanBtWVd4elpTd2laWFpsYm5SZmFXUWlPaUkxTVRFMU1qbGlaQzFrWkRrMExUUTVaREV0WVRGaFl5MW1OVEJpTjJJeU5qZGpZVE1pTENKMGIydGxibDkxYzJVaU9pSnBaQ0lzSW1GMWRHaGZkR2x0WlNJNk1UWXhNVGt4T0Rrek9Td2lhWE56SWpvaWFIUjBjSE02WEM5Y0wyTnZaMjVwZEc4dGFXUndMbVYxTFhkbGMzUXRNUzVoYldGNmIyNWhkM011WTI5dFhDOWxkUzEzWlhOMExURmZVMWR6VjBGMk9XUTBJaXdpYm1GdFpTSTZJbUZ1WkhKbGN5SXNJbU52WjI1cGRHODZkWE5sY201aGJXVWlPaUkyTVRZd016RmxOUzAzWlRZMkxUUmlORGd0T1RVMU9TMWhaR1JoTVdZME5XSmlNakVpTENKbGVIQWlPakUyTVRFNU16RXlNek1zSW1saGRDSTZNVFl4TVRreU56WXpNeXdpWlcxaGFXd2lPaUpoYm1SeVpYTmpZWE4wYVd4c2IyUm1RR2R0WVdsc0xtTnZiU0o5LkRnTGlzZEJWb3pVU3E4UEtCdURTUG80VG0xV3FwVkNOR3hwNVZDejJkMkpLeV9UeHRCYmdUaExMek9EREdQU0VhNVd5c1lGeVRZdHJNb19nd3ItSFl1dUt1RExNUmNlc2haS3AxclNLUS02VllUVWhuQnJuNUp4ajF0WHhwU2tmVVp4MEE1VW1EWGs0SUQ4NGw2RTBrV0dNeEtSNTBaXzNsUV9uMjNueHFReWhuUVQyLUdaZy15RWtVWGJmWVhmUTgyNFAyLVg1ZTEyRUZOdTMyVXNXVTNmeGQxSjlMb2IyVE1rRlVQWEhhSC1qTmdWcUVzeHVaeDhyQksyWndRVElaS3luWUdZekZKdmJ3UlFVQlRzUUswM3g4NHlITDJ6T1hkbjNMbzlrTEx5U2t5SHJXcWtwTHR1S2tILXhqN0h4UHdWT3RlOUFialV3akEzN2JPNWVWQSIsImhvc3QiOiJocWl0eXFtcTc1ZTI1cDNnM2xkanZ5eHFobS5hcHBzeW5jLWFwaS5ldS13ZXN0LTEuYW1hem9uYXdzLmNvbSJ9";
// console.log(header);

// const WSEndpoint =
//   process.env.VUE_APP_GRAPHQL_ENDPOINT_WS +
//   "?header=" +
//   btoa(JSON.stringify(header)) +
//   "&payload=" +
//   btoa(JSON.stringify({}));
// Http endpoint

//const httpEndpoint = process.env.VUE_APP_API_URL;

// Files URL root
// export const filesRoot =
//   process.env.VUE_APP_FILES_ROOT ||
//   httpEndpoint.substr(0, httpEndpoint.indexOf("/graphql"));

// Vue.prototype.$filesRoot = filesRoot;

// Config
const defaultOptions = {
  // You can use `https` for secure connection (recommended in production)
  // httpEndpoint,
  // You can use `wss` for secure connection (recommended in production)
  // Use `null` to disable subscriptions
  //TODO -  activate to use ws
  // wsEndpoint: process.env.VUE_APP_GRAPHQL_ENDPOINT_WS,
  // wsEndpoint: WSEndpoint,
  wsEndpoint: null,
  // LocalStorage token
  tokenName: AUTH_TOKEN,
  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,
  // Use websockets for everything (no HTTP)
  // You need to pass a `wsEndpoint` for this to work
  websocketsOnly: false,
  // Is being rendered on the server?
  ssr: false,

  // Override default apollo link
  // note: don't override httpLink here, specify httpLink options in the
  // httpLinkOptions property of defaultOptions.
  // link: myLink
  httpLinkOptions: {
    fetch: async (uri, options) => {
      const session = await Auth.currentSession().catch(() => null);
      if (!session?.accessToken?.jwtToken) {
        options.headers["x-api-key"] =
          process.env.VUE_APP_WEB_GUEST_USER_API_KEY;
      }
      return fetch(uri, options);
    }
  },
  // Override default cache
  cache: new InMemoryCache({ fragmentMatcher }),
  //TODO - verify if this option is ok
  $query: {
    fetchPolicy: "cache-and-network"
  }

  // Override the way the Authorization header is set
  // getAuth: async () => {
  // }

  // Additional ApolloClient options
  // apollo: { ... }

  // Client local data (see apollo-link-state)
  // clientState: { resolvers: { ... }, defaults: { ... } }
};

const awsOptions = {
  httpEndpoint: process.env.VUE_APP_API_URL,
  getAuth: async () => {
    const session = await Auth.currentSession().catch(() => null);
    return session?.accessToken?.jwtToken;
  }
};

const kenticoOptions = {
  httpEndpoint: process.env.VUE_APP_KENTICO_API_URL,
  getAuth: async () => {
    return `Bearer ${process.env.VUE_APP_PREVIEW_KENTICO_API_KEY}`;
  }
};

// Call this in the Vue app file
export function createProvider() {
  // Create apollo client
  // aws
  const { apolloClient: awsClient, wsClient } = createApolloClient({
    ...defaultOptions,
    ...awsOptions
  });
  awsClient.wsClient = wsClient;

  // kentico
  const { apolloClient: kenticoClient } = createApolloClient({
    ...defaultOptions,
    ...kenticoOptions
  });

  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    clients: {
      awsClient,
      kenticoClient
    },
    defaultClient: awsClient,
    defaultOptions: {
      $query: {
        fetchPolicy: "cache-and-network"
      }
    },
    // errorHandler({ graphQLErrors, networkError }) {
    errorHandler({ graphQLErrors }) {
      apolloErrorHandling(graphQLErrors);
    }
  });

  return apolloProvider;
}

//TODO add this to the vuex auth module
// Manually call this when user log in
export async function apolloOnLogin(apolloClient, token) {
  if (typeof localStorage !== "undefined" && token) {
    localStorage.setItem(AUTH_TOKEN, token);
  }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient);
  try {
    await apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log("%cError on cache reset (login)", "color: orange;", e.message);
  }
}

//TODO add this to the vuex auth module
// Manually call this when user log out
export async function apolloOnLogout(apolloClient) {
  if (typeof localStorage !== "undefined") {
    localStorage.removeItem(AUTH_TOKEN);
  }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient);
  try {
    await apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log("%cError on cache reset (logout)", "color: orange;", e.message);
  }
}
