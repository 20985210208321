export function getActualToken() {
  try {
    return (
      JSON.parse(window.localStorage.getItem("auth.currentUser"))?.authToken ||
      null
    );
  } catch (e) {
    return null;
  }
}
export function getStateItem(key) {
  return JSON.parse(window.localStorage.getItem(key)) || null;
}
export function saveState(key, state) {
  window.localStorage.setItem(key, JSON.stringify(state));
}
