import { redirectUnauthenticatedUsersToHome } from "@/middleware/Auth";
import teamsRedirects from "@/router/redirects/teamsRedirects.js";
import { redirectDisabledFeatureToNotFound } from "@/middleware/featureToggle";

const routes = [
  ...teamsRedirects,
  {
    path: "/my-profile",
    name: "MyProfilePage",
    component: () => import("@/views/LoggedPages/ProfilePage.vue"),
    meta: {
      middleware: [redirectUnauthenticatedUsersToHome],
      layout: "LayoutLoggedEmpty",
      hideMenuMobile: true
    }
  },
  {
    path: "/profile/:screenName",
    name: "ProfilePage",
    component: () => import("@/views/LoggedPages/ProfilePage.vue"),
    meta: {
      layout: "LayoutLoggedEmpty",
      hideMenuMobile: true,
      page_view: {
        page_title_modifier: route => {
          return route.params.screenName;
        }
      }
    }
  },
  {
    path: "/profile-id/:profileId",
    name: "ProfilePageById",
    component: () => import("@/views/LoggedPages/ProfilePage.vue"),
    meta: {
      layout: "LayoutLoggedEmpty"
    }
  },
  {
    path: "/post/:id",
    name: "PostPage",
    component: () => import("@/views/LoggedPages/PostPage.vue"),
    meta: {
      layout: "LayoutLoggedEmpty"
    }
  },
  {
    path: "/profile/:screenName/posts",
    name: "ProfilePostsPage",
    component: () => import("@/views/LoggedPages/ProfilePostsPage.vue"),
    meta: {
      layout: "LayoutLoggedEmpty",
      page_view: {
        page_title_modifier: route => {
          return route.params.screenName;
        }
      }
    }
  },
  {
    path: "/profile/:screenName/teams",
    name: "ProfileTeamsPage",
    component: () => import("@/views/LoggedPages/ProfileTeamsPage.vue"),
    meta: {
      layout: "LayoutLoggedEmpty",
      view: "teams",
      page_view: {
        page_title_modifier: route => {
          return route.params.screenName;
        }
      }
    }
  },
  {
    path: "/profile/:screenName/captaincies",
    name: "ProfileCaptainciesPage",
    component: () => import("@/views/LoggedPages/ProfileTeamsPage.vue"),
    meta: {
      layout: "LayoutLoggedEmpty",
      view: "captaincies",
      page_view: {
        page_title_modifier: route => {
          return route.params.screenName;
        }
      }
    }
  },
  {
    path: "/profile/:screenName/followers",
    name: "ProfileFollowersPage",
    component: () => import("@/views/LoggedPages/ProfileFollowersPage.vue"),
    meta: {
      layout: "LayoutLoggedEmpty",
      view: "followers",
      page_view: {
        page_title_modifier: route => {
          return route.params.screenName;
        }
      }
    }
  },
  {
    path: "/profile/:screenName/following",
    name: "ProfileFollowingPage",
    component: () => import("@/views/LoggedPages/ProfileFollowersPage.vue"),
    meta: {
      layout: "LayoutLoggedEmpty",
      view: "following",
      page_view: {
        page_title_modifier: route => {
          return route.params.screenName;
        }
      }
    }
  },
  {
    path: "/team/:slug/posts",
    name: "TeamPostsPage",
    component: () => import("@/views/LoggedPages/TeamPostsPage.vue"),
    meta: {
      layout: "LayoutLoggedEmpty",
      page_view: {
        page_title_modifier: route => {
          return route.params.slug;
        }
      }
    }
  },
  {
    path: "/timeline",
    name: "TimelinePostPage",
    component: () => import("@/views/LoggedPages/TimelinePostPage.vue"),
    meta: {
      middleware: [redirectUnauthenticatedUsersToHome],
      layout: "LayoutLoggedEmpty"
    }
  },
  {
    path: "/my-profile/edit",
    name: "ProfileEditPage",
    component: () => import("@/views/LoggedPages/ProfileEditPage.vue"),
    meta: {
      middleware: [
        redirectUnauthenticatedUsersToHome,
        redirectDisabledFeatureToNotFound
      ],
      layout: "LayoutLoggedEmpty",
      // disabledFeatureName: "edit-profile",
      hideMenuMobile: true
    }
  },
  {
    path: "/moderation/profile/:profileId",
    name: "ModerateProfilePage",
    component: () => import("@/views/LoggedPages/ModerateProfilePage.vue"),
    meta: {
      middleware: [redirectUnauthenticatedUsersToHome],
      layout: "LayoutDefault"
    }
  },
  {
    path: "/moderation/profile/:profileId/edit",
    name: "ModerateProfileEditPage",
    component: () => import("@/views/LoggedPages/ProfileEditPage.vue"),
    meta: {
      middleware: [redirectUnauthenticatedUsersToHome],
      layout: "LayoutLoggedEmpty"
    }
  },
  {
    path: "/change-password",
    name: "ChangePasswordPage",
    component: () => import("@/views/LoggedPages/ChangePasswordPage.vue"),
    meta: {
      middleware: [redirectUnauthenticatedUsersToHome],
      layout: "LayoutLoggedEmpty",
      hideMenuMobile: true
    }
  },
  {
    path: "/discover",
    redirect: () => "/"
  },
  {
    path: "/inbox",
    name: "Inbox",
    component: () => import("@/views/LoggedPages/Inbox.vue"),
    meta: {
      middleware: [redirectUnauthenticatedUsersToHome],
      // transition pending it is a problem with different layouts
      // if this is required we should consider unify the layouts
      //transitionName: "slide-fade",
      //transitionOut: "out-in",
      layout: "LayoutLoggedEmpty"
    }
  },
  {
    path: "/inbox/:id",
    name: "InboxMessage",
    component: () => import("@/views/LoggedPages/InboxMessage.vue"),
    meta: {
      middleware: [redirectUnauthenticatedUsersToHome],
      layout: "LayoutLoggedEmpty"
    }
  },
  {
    path: "/account-settings",
    name: "AccountSettings",
    component: () => import("@/views/LoggedPages/AccountSettings.vue"),
    meta: {
      middleware: [redirectUnauthenticatedUsersToHome],
      hideMenuMobile: true,
      layout: "LayoutLoggedEmpty"
    }
  },
  {
    path: "/contact-preferences",
    name: "AccountContactPreferencesPage",
    component: () => import("@/views/LoggedPages/ContactPreferencesPage.vue"),
    meta: {
      middleware: [redirectUnauthenticatedUsersToHome],
      hideMenuMobile: true,
      layout: "LayoutLoggedEmpty"
    }
  },
  {
    path: "/federate-birthdate",
    name: "FederateBirthDate",
    component: () => import("@/views/FederateBirthDate.vue"),
    meta: {
      layout: "LayoutDefault"
    }
  },
  // old discover page
  // {
  //   path: "/discover",
  //   name: "discoverPage",
  //   component: () =>  import("@/views/LoggedPages/DiscoverPage.vue"),
  //   meta: {
  //     layout: "LayoutLoggedEmpty"
  //   }
  // },
  {
    path: "/team/:slug",
    name: "TeamPage",
    component: () => import("@/views/LoggedPages/TeamPage.vue"),
    meta: {
      layout: "LayoutLoggedEmpty",
      hideMenuMobile: true,
      page_view: {
        page_title_modifier: route => {
          return route.params.slug;
        }
      }
    }
  },
  {
    path: "/team/:slug/tasks",
    name: "TeamTasks",
    component: () => import("@/views/LoggedPages/TeamTasks.vue"),
    meta: {
      layout: "LayoutLoggedEmpty",
      hideMenuMobile: true,
      page_view: {
        page_title_modifier: route => {
          return route.params.slug + " tasks";
        }
      }
    }
  },
  {
    path: "/team/:slug/members",
    name: "TeamMembersPage",
    component: () => import("@/views/LoggedPages/TeamMembersPage.vue"),
    meta: {
      middleware: [redirectDisabledFeatureToNotFound],
      layout: "LayoutLoggedEmpty",
      hideMenuMobile: true,
      // disabledFeatureName: "team-members-page",
      page_view: {
        page_title_modifier: route => {
          return route.params.slug;
        }
      }
    }
  },
  {
    path: "/team/:slug/task/:taskId",
    name: "TaskPage",
    component: () => import("@/views/LoggedPages/TaskPage.vue"),
    meta: {
      layout: "LayoutLoggedEmpty",
      hideMenuMobile: true,
      page_view: {
        page_title_modifier: route => {
          return `${route.params.slug}-${route.params.taskId}`;
        }
      }
    }
  },
  {
    path: "/team/:slug/donation-task/:taskId",
    name: "DonationTaskPage",
    component: () => import("@/views/LoggedPages/DonationTaskPage.vue"),
    meta: {
      layout: "LayoutLoggedEmpty",
      hideMenuMobile: true,
      page_view: {
        page_title_modifier: route => {
          return `${route.params.slug}-${route.params.taskId}`;
        }
      }
    }
  },
  {
    path: "/register/account-verified",
    name: "AccountVerified",
    component: () => import("@/views/signUp/AccountVerified.vue"),
    meta: { layout: "LayoutEmpty" }
  },
  {
    path: "/register/incomplete-profile",
    name: "IncompleteProfile",
    component: () => import("@/views/LoggedPages/IncompleteProfile.vue"),
    meta: {
      middleware: [redirectUnauthenticatedUsersToHome],
      layout: "LayoutDefault"
    }
  },
  {
    path: "/team/:slug/captain/:profileSlug",
    name: "CaptainProfilePage",
    component: () => import("@/views/LoggedPages/HardcodedProfilePage.vue"),
    meta: {
      layout: "LayoutLoggedEmpty",
      hideMenuMobile: true,
      page_view: {
        page_title_modifier: route => {
          return `${route.params.slug}-${route.params.profileSlug}`;
        }
      }
    }
  },
  {
    path: "/team/:slug/supporter/:profileSlug?",
    name: "SupporterProfilePage",
    component: () => import("@/views/LoggedPages/HardcodedProfilePage.vue"),
    meta: {
      layout: "LayoutLoggedEmpty",
      hideMenuMobile: true,
      page_view: {
        page_title_modifier: route => {
          return `${route.params.slug}-${route.params.profileSlug}`;
        }
      }
    }
  }
];
export default routes;
