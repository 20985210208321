var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"avatar flex justify-center items-center rounded-full bg-blue-900 cursor-default",class:{
    'avatar-sm': _vm.avatarType === 'small',
    'avatar-md': _vm.avatarType === 'medium',
    'avatar-lg': _vm.avatarType === 'large',
    'cursor-pointer hover:bg-blue-800': _vm.clickable
  },attrs:{"tabindex":"0"},on:{"click":function($event){return _vm.$emit('click')},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$emit('click')}}},[(_vm.profileImageUrl)?_c('div',{staticClass:"h-full w-full bg-gray-200 relative bg-no-repeat bg-center bg-cover rounded-full flex-shrink-0",style:(("background-image: url('" + _vm.profileImageUrl + "')"))}):_c('span',{staticClass:"text-white text-2xl font-bold uppercase",class:{
      'text-4xl md:text-7xl': _vm.avatarType === 'large' || _vm.text === 'large'
    }},[_vm._v(_vm._s(_vm.initials))])])}
var staticRenderFns = []

export { render, staticRenderFns }