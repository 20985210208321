const system_config = require("@/constants/config.json");

export function isValidAge(minAge, ageToVerify) {
  let dateParts = ageToVerify.split("-");
  let userDate = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
  let today = new Date();
  let yearsDiff = today.getFullYear() - userDate.getFullYear();
  if (yearsDiff < minAge) {
    return false;
  }
  if (yearsDiff == minAge) {
    let monthsDif = today.getMonth() - userDate.getMonth();
    if (monthsDif > 0) {
      return false;
    } else {
      let daysDiff = today.getDate() - userDate.getDate();
      if (daysDiff < 0) {
        return false;
      }
    }
  }
  return true;
}

// Validates that the input string is a valid date formatted as "dd-mm-yyyy"
export function isValidDate(value) {
  if (value == null || value == "") {
    // console.log("null or empty");
    return false;
  }
  // First check for the pattern
  if (!/^\d{1,2}-\d{1,2}-\d{4}$/.test(value)) {
    // console.log("invalid pattern");
    return false;
  }

  // Parse the date parts to integers
  const parts = value.split("-");
  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10);
  const year = parseInt(parts[2], 10);

  if (month < 1 || month > 12) {
    // console.log("invalid month");
    return false;
  }

  const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  // Adjust for leap years
  if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0)) {
    monthLength[1] = 29;
  }
  // Check the range of the day
  if (day < 1 || day > monthLength[month - 1]) {
    // console.log("invalid day range");
    return false;
  }
  return true;
}

export function isValidContactPreferencesAttribute(value) {
  if (typeof value === "string") {
    const options = value.split(",");
    if (
      (options.includes(system_config.teamUpdatesYes) ||
        options.includes(system_config.teamUpdatesNo)) &&
      (options.includes(system_config.recommendedContentYes) ||
        options.includes(system_config.recommendedContentNo)) &&
      (options.includes(system_config.marketingContentYes) ||
        options.includes(system_config.marketingContentNo))
    ) {
      return true;
    }
  }
  return false;
}
/**
 * validate value and/or set default values
 */
export function loadContactPreferencesAttribute(value, callback = null) {
  const defaultContactPreferences = {
    teamUpdates: system_config.teamUpdatesYes,
    recommendedContent: system_config.recommendedContentNo,
    marketingContent: system_config.marketingContentNo
  };
  const contactPreferences = [];
  let options = [];
  if (typeof value === "string") {
    options = value.split(",");
  }
  // teamUpdates
  if (options.includes(system_config.teamUpdatesYes)) {
    contactPreferences.push(system_config.teamUpdatesYes);
  } else if (options.includes(system_config.teamUpdatesNo)) {
    contactPreferences.push(system_config.teamUpdatesNo);
  } else {
    contactPreferences.push(defaultContactPreferences.teamUpdates);
  }

  // recommendedContent
  if (options.includes(system_config.recommendedContentYes)) {
    contactPreferences.push(system_config.recommendedContentYes);
  } else if (options.includes(system_config.recommendedContentNo)) {
    contactPreferences.push(system_config.recommendedContentNo);
  } else {
    contactPreferences.push(defaultContactPreferences.recommendedContent);
  }

  // marketingContent
  if (options.includes(system_config.marketingContentYes)) {
    contactPreferences.push(system_config.marketingContentYes);
  } else if (options.includes(system_config.marketingContentNo)) {
    contactPreferences.push(system_config.marketingContentNo);
  } else {
    contactPreferences.push(defaultContactPreferences.marketingContent);
  }
  options.map(option => {
    if (!contactPreferences.some(preference => option === preference)) {
      contactPreferences.push(option);
    }
  });
  if (typeof callback === "function") {
    callback(contactPreferences);
  }
  return contactPreferences;
}

export function dateIsAfter1900(date) {
  let dateParts = date.split("-");
  let userDate = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
  return userDate.getFullYear() >= 1900;
}

export function dateIsOver12YearsAgo(date) {
  let dateParts = date.split("-");
  let userDate = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
  let today = new Date();
  let yearsDiff = today.getFullYear() - userDate.getFullYear();
  if (yearsDiff < 12) {
    return false;
  }
  if (yearsDiff == 12) {
    let monthsDif = today.getMonth() - userDate.getMonth();
    if (monthsDif > 0) {
      return false;
    } else {
      let daysDiff = today.getDate() - userDate.getDate();
      if (daysDiff < 0) {
        return false;
      }
    }
  }
  return true;
}

const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export function isValidEmailFormat(email) {
  return emailRegex.test(String(email).toLowerCase());
}
export function isValidEmailLength(email) {
  return String(email).length <= 128;
}

export function customPasswordValidator(value) {
  if (typeof value === "string") {
    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/.test(value);
  } else {
    return false;
  }
}

export function isUserFederated(id) {
  return ["Google", "Facebook"].some(type => id.includes(type));
}

export function scrollToElement(elId) {
  document.querySelector(`[id^="${elId}"]`)?.scrollIntoView();
}

export function formatPointsOnCompletion(points) {
  return Number(points / 100);
}

export function stripHtml(html) {
  return html.replace(/<[^>]*>?/gm, "");
}

export function findNextTabStop(el) {
  const universe = document.querySelectorAll(
    "input, button, select, textarea, a[href], div"
  );
  const list = Array.prototype.filter.call(universe, function(item) {
    return item.tabIndex >= "0";
  });
  const index = list.indexOf(el);
  return list[index - 1] || list[0];
}

export function standardizeAppsyncPoints(value) {
  return value / 100;
}

export function standardizeBirthDate(date) {
  const datePieces = date.split("-");
  if (datePieces[0].length == 1) {
    datePieces[0] = `0${datePieces[0]}`;
  }
  if (datePieces[1].length == 1) {
    datePieces[1] = `0${datePieces[1]}`;
  }
  return datePieces.join("-");
}
