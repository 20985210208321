<template>
  <div id="layout" class="h-full w-full bg-gray-50 overflow-y-auto">
    <div id="container" class="max-w-desktop mx-auto contents">
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  name: "LayoutDefault"
};
</script>
<style lang="postcss" scoped>
#container {
  height: fit-content;
}
</style>
