import VueI18n from "vue-i18n";
import Vue from "vue";

Vue.use(VueI18n, {
  rtl: true
});

const i18n = new VueI18n({
  locale: "en",
  fallbackLocale: "en",
  messages: {
    es: {
      message: require("@/constants/i18n/translations-es.json"),
      flag: require("@/assets/img/flags/es.svg")
    },
    en: {
      message: require("@/constants/i18n/translations-en.json"),
      flag: require("@/assets/img/flags/en.svg")
    },
    pu: {
      message: require("@/constants/i18n/translations-pu.json"),
      flag: require("@/assets/img/flags/en.svg")
    }
  }
});
// Create VueI18n instance with options
export default i18n;
