// used to de-authenticate authenticated users under the age of 12
import { FeatureToggleComponent as feature } from "vue-feature-toggle";

export async function redirectDisabledFeatureToNotFound({ to, next, halt }) {
  if (
    to.meta.disabledFeatureName &&
    !feature.isVisible(to.meta.disabledFeatureName)
  ) {
    return halt({
      name: "NotFound",
      component: () => import("@/views/NotFoundPage.vue"),
      meta: {
        layout: "LayoutDefault"
      }
    });
  } else {
    return next();
  }
}
