<template>
  <div
    :id="id"
    class="modal overflow-hidden"
    tabindex="0"
    :class="{ active: isOpen, out: closed }"
    @keydown.esc="closeModal()"
  >
    <div class="modal-bg" @click="closeModal()" />
    <div
      class="modal-content mx-auto w-full flex flex-col overflow-auto"
      :class="[{ out: closed }, size, bgColor]"
    >
      <div
        v-if="showHeaders"
        class="header relative flex justify-center items-center text-center mt-3 h-5"
      >
        <div class="title">
          <slot name="title"></slot>
        </div>
        <span class="close" @click="closeModal()" v-if="showCloseButton">
          <img src="@/assets/img/icons/close-x.svg" alt="close" />
        </span>
      </div>
      <slot />
    </div>
  </div>
</template>
<script>
//todo => add prop with animation type, define different types of animation
export default {
  name: "ModalComponent",
  props: {
    value: {
      type: Boolean,
      required: true
    },
    showCloseButton: {
      type: Boolean,
      required: false,
      default: true
    },
    showHeaders: {
      type: Boolean,
      required: false,
      default: true
    },
    size: {
      type: String,
      required: false,
      default: "h-full"
    },
    bgColor: {
      type: String,
      required: false,
      default: "bg-gray-50"
    },
    id: {
      type: String,
      required: false,
      default: "myModal"
    }
  },
  data() {
    return {
      closed: false,
      isOpen: false
    };
  },
  watch: {
    value(val) {
      if (val) {
        this.closed = false;
        this.isOpen = true;
        const modal = document.getElementById(this.id);
        setTimeout(function() {
          modal.focus();
        }, 500);
      } else {
        if (this.isOpen) {
          this.closeModal();
        }
      }
    }
  },
  methods: {
    closeModal() {
      this.closed = true;
      setTimeout(() => {
        this.isOpen = false;
        this.$emit("input", false);
      }, 100);
    }
  }
};
</script>
<style lang="postcss" scoped>
.modal {
  display: none;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  padding-top: 0;
  margin: 0 auto;
  z-index: 20;

  &:focus {
    border: none;
    outline: none;
  }

  .modal-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    animation: fade-in 0.2s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }

  /* Modal Content */
  .modal-content {
    z-index: 1;
    border-radius: 10px 10px 0 0;

    @media screen and (min-width: 600px) {
      border-radius: 10px;
    }

    opacity: 0;
  }

  &.active {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;

    .modal-content {
      animation: scale-up 0.4s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    }
  }

  &.out {
    .modal-bg {
      animation: fade-out 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    }

    .modal-content {
      animation: scale-down 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    }
  }

  /* The Close Button */
  .close {
    position: absolute;
    right: 18px;
    z-index: 1;
    display: flex;
    justify-content: center;
    width: 18.25px;
    height: 18.25px;

    img {
      width: 100%;
    }
  }

  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
}

@keyframes fade-in {
  0% {
    background: rgb(0 0 0 / 0%);
  }

  100% {
    background: rgb(0 0 0 / 70%);
  }
}

@keyframes fade-out {
  0% {
    background: rgb(0 0 0 / 70%);
  }

  100% {
    background: rgb(0 0 0 / 0%);
  }
}

@keyframes scale-up {
  0% {
    transform: scale(0.8) translateY(1000px);
    opacity: 0;
  }

  100% {
    transform: scale(1) translateY(0);
    opacity: 1;
  }
}

@keyframes quick-scale-down {
  0% {
    transform: scale(1);
  }

  99.9% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes scale-down {
  0% {
    transform: scale(1) translateY(0);
    opacity: 1;
  }

  100% {
    transform: scale(0.8) translateY(1000px);
    opacity: 0;
  }
}
</style>
