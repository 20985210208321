<template>
  <div @keyup.enter="$emit('click')" @click="$emit('click')">
    <svg
      viewBox="0 0 29.25811 11.629823"
      height="13.629823mm"
      width="29.25811mm"
    >
      <g
        transform="translate(-74.902558,-149.42763)"
        id="layer1"
        inkscape:groupmode="layer"
        inkscape:label="Layer 1"
      >
        <g
          transform="matrix(0.26458333,0,0,0.26458333,74.902558,149.4182)"
          id="g844"
          style="fill: none;"
        >
          <path
            d="m 0,26.3928 c 5.383,0 10.5605,0 15.7381,0 C 12.4742,25.6564 9.78371,24.0196 7.72717,21.4236 4.56508,17.4311 3.93109,12.9268 5.84087,8.30332 7.77805,3.62127 11.3941,0.546777 16.5756,0.245969 22.393,-0.0919514 28.2417,0.0897052 34.0767,0.0467327 c 0.4167,-0.0039066 0.8335,0 1.3442,0 0.0216,0.4414453 0.0529,0.7871793 0.0529,1.1309573 0.0039,8.30152 0.0195,16.60301 -0.002,24.90451 -0.0215,8.3992 -5.426,15.353 -13.4506,17.3629 C 12.2081,45.9023 2.19351,39.5307 0.332646,29.6177 0.142842,28.6039 0.115448,27.5628 0,26.3928 Z"
            :class="color"
            id="path833"
          />
        </g>
        <g
          transform="matrix(0.26458333,0,0,0.26458333,84.496495,149.428)"
          id="g857"
          style="fill: none;"
        >
          <path
            d="m 42.6529,21.9844 c 0.1311,4.8813 -1.0176,9.3641 -3.9879,13.2903 -4.5181,5.9712 -10.5214,8.9441 -18.047,8.657 C 10.9889,43.5645 2.52596,36.2083 1.12102,26.6606 0.324625,21.25 0.506602,15.8413 3.30083,10.8233 7.49608,3.28942 15.6811,-0.775391 24.2712,0.123126 32.7126,1.00602 40.79,8.20978 42.2419,16.9391 c 0.2759,1.6583 0.2818,3.3616 0.411,5.0453 z"
            :class="color"
            id="path846"
          />
        </g>
        <g
          transform="matrix(0.26458333,0,0,0.26458333,95.959062,149.4156)"
          id="g870"
          style="fill: none;"
        >
          <path
            d="m 0.981831,0.046875 c 5.905449,0 11.703269,0 17.634169,0 0,10.717725 0,21.402325 0,32.164925 4.025,-0.0273 7.3104,1.3712 9.7974,4.3989 1.7024,2.0744 2.5692,4.4867 2.5848,7.3404 -0.4207,0 -0.7983,0 -1.176,0 C 23.2672,43.9531 16.7101,43.832 10.1589,43.998 5.49016,44.1152 0.852684,40.4157 0.899646,34.7902 0.975959,25.9046 0.919214,17.0171 0.919214,8.12962 0.919214,5.688 0.915305,3.24638 0.917262,0.804754 0.921175,0.584032 0.956394,0.361356 0.981831,0.046875 Z"
            :class="color"
            id="path859"
          />
        </g>
      </g>
    </svg>
  </div>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      required: false,
      default: "green"
    }
  }
};
</script>
<style lang="postcss" scoped>
img {
  height: 43.95px;
}

.green {
  fill: theme("colors.green.300");
}

.blue {
  fill: theme("colors.blue.500");
}

.red {
  fill: theme("colors.red.900");
}

.yellow {
  fill: theme("colors.yellow.300");
}

.orange {
  fill: theme("colors.yellow.900");
}

.pink {
  fill: theme("colors.pink.400");
}
</style>
