import { Auth } from "@aws-amplify/auth";

// used to redirect unauthenticated users from authenticated-only routes
export async function redirectUnauthenticatedUsersToHome({ next, to, halt }) {
  try {
    const currentAuthenticatedUser = await Auth.currentAuthenticatedUser();
    if (currentAuthenticatedUser) {
      return next();
    } else {
      throw false;
    }
  } catch (e) {
    return halt({
      name: "SignInPage",
      params: {
        toast: { message: "You must be authenticated to access that page" }
      },
      query: { redirect: to.path }
    });
  }
}

// used to redirect authenticated users away from the route
export async function redirectAuthenticatedUsersToHome({ next, halt }) {
  try {
    await Auth.currentAuthenticatedUser();
    console.log("user is authenticated - redirect to home");
    return halt({
      name: "HomeIn",
      params: {
        toast: { message: "You are logged in" }
      }
    });
  } catch (err) {
    return next();
  }
}
