<template>
  <div
    tabindex="0"
    class="avatar flex justify-center items-center rounded-full bg-blue-900 cursor-default"
    :class="{
      'avatar-sm': avatarType === 'small',
      'avatar-md': avatarType === 'medium',
      'avatar-lg': avatarType === 'large',
      'cursor-pointer hover:bg-blue-800': clickable
    }"
    @click="$emit('click')"
    @keyup.enter="$emit('click')"
  >
    <div
      v-if="profileImageUrl"
      class="h-full w-full bg-gray-200 relative bg-no-repeat bg-center bg-cover rounded-full flex-shrink-0"
      :style="`background-image: url('${profileImageUrl}')`"
    ></div>
    <span
      v-else
      class="text-white text-2xl font-bold uppercase"
      :class="{
        'text-4xl md:text-7xl': avatarType === 'large' || text === 'large'
      }"
      >{{ initials }}</span
    >
  </div>
</template>

<script>
export default {
  name: "Avatar",
  props: {
    avatarType: {
      type: String,
      required: false,
      default: "medium",
      validator: value => {
        return ["small", "medium", "large"].indexOf(value) !== -1;
      }
    },
    text: {
      type: String,
      required: false,
      default: "",
      validator: value => {
        return ["", "large"].indexOf(value) !== -1;
      }
    },
    clickable: {
      type: Boolean,
      required: false,
      default: true
    },
    displayName: {
      type: String,
      required: true
    },
    profileImageUrl: {
      type: String,
      required: false
    }
  },
  computed: {
    initials() {
      return this.displayName.charAt(0);
    }
  }
};
</script>

<style lang="postcss">
.avatar-sm {
  min-width: 42px;
  height: 42px;
}

.avatar-md {
  width: 52px;
  height: 52px;
}

.avatar-lg {
  width: 156px;
  height: 156px;
}

@media screen and (max-width: 768px) {
  .avatar-lg {
    width: 88px;
    height: 88px;
  }
}
</style>
