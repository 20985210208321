import store from "@/store";
import { jwtTokenPayload } from "@/utils/cognitoAuthToken";
import { isValidAge } from "@/utils/basicHelpers.js";
import i18n from "@/plugins/i18n.js";

// used to de-authenticate authenticated users under the age of 12
export async function ageGate({ next, halt }) {
  let userAttributes = await jwtTokenPayload();
  if (userAttributes) {
    if (!isValidAge(12, userAttributes["profile:birthdate"])) {
      store.dispatch("general/setHomepageToast", {
        type: "error",
        message: i18n.t("message.age-gate-exception"),
        duration: 0,
        position: "top"
      });
      store.dispatch("auth/logout");
      return halt(false);
    }
  }
  return next();
}
