import Vue from "vue";
import Vuex from "vuex";
import dispatchActionForAllModules from "@/utils/dispatch-action-for-all-modules";

import modules from "./modules";

Vue.use(Vuex);

Vue.filter("htmlToPlain", function(value) {
  if (!value) return "";
  return value.replace(/<[^>]+>/gm, "");
});

Vue.filter("livesImpactedFormat", function(value) {
  if (typeof value !== "number") {
    value = Number(value.replaceAll(",", ""));
  }
  let unit = "";
  let convertedValue = value;
  if (value < 1000) convertedValue = value;
  if (value >= 1000 && value < 1000000) {
    convertedValue = value / 1000;
    unit = "K";
  }
  if (value >= 1000000) {
    convertedValue = value / 1000000;
    unit = "M";
  }
  const decimals = convertedValue - Math.floor(convertedValue);
  let decimalsChars = 1;
  if (decimals < 0.1) {
    decimalsChars = 0;
  }
  if (
    value < 1000 &&
    convertedValue.toString().split(".").length > 1 &&
    Number(
      convertedValue
        .toString()
        .split(".")[1]
        .substring(1, 2)
    ) > 0
  ) {
    decimalsChars = 2;
  }
  return `${convertedValue.toFixed(decimalsChars)}${unit}`;
});

const store = new Vuex.Store({
  modules,
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  strict: process.env.NODE_ENV !== "production"
});

export default store;

// Automatically run the `init` action for every module,
// if one exists.
dispatchActionForAllModules("init");
