<template>
  <div
    id="layout"
    class="h-full pb-minibar block overflow-y-auto overflow-x-hidden scrollbar-hidden md:px-4"
  >
    <div class="max-w-desktop mx-auto contents h-full">
      <div class="container">
        <LogoHeader :showBackButton="false" />
      </div>
      <slot />
    </div>
    <MiniMenu class="w-full" />
  </div>
</template>
<script>
import LogoHeader from "@/components/shared/LogoHeader.vue";
import MiniMenu from "@/components/shared/MiniMenu.vue";

export default {
  name: "LayoutLogged",
  components: {
    LogoHeader,
    MiniMenu
  },
  methods: {
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push({ name: "HomeIn" });
    }
  }
};
</script>
