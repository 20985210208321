import { redirectAuthenticatedUsersToHome } from "@/middleware/Auth";
import { checkForIncompleteProfile } from "@/middleware/incompleteProfile";
import { ageGate } from "@/middleware/ageGate";
const routes = [
  {
    path: "/",
    name: "HomeIn",
    component: () => import("@/views/LoggedPages/HomeIn.vue"),
    meta: {
      middleware: [checkForIncompleteProfile, ageGate],
      layout: "LayoutLoggedEmpty"
    }
  },
  {
    path: "/about",
    name: "About",
    component: () => import("@/views/About.vue"),
    meta: {
      layout: "LayoutDefault"
    }
  },
  {
    path: "/login",
    name: "SignInPage",
    component: () => import("@/views/SignInPage.vue"),
    meta: {
      middleware: [redirectAuthenticatedUsersToHome],
      layout: "FormLayout"
    }
  },
  {
    path: "/oauth/login",
    component: () => import("@/views/RedirectPage.vue"),
    meta: {
      to: { name: "HomeIn" },
      layout: "LayoutDefault"
    }
  },
  {
    path: "/oauth/logout",
    redirect: () => "/"
  },
  {
    path: "/register",
    name: "SignUp",
    component: () => import("@/views/SignUp.vue"),
    meta: {
      middleware: [redirectAuthenticatedUsersToHome],
      layout: "FormLayout"
    },
    children: [
      {
        path: "link-expired",
        name: "LinkExpired",
        component: () => import("@/views/signUp/LinkExpired.vue"),
        meta: { layout: "FormLayout" }
      },
      {
        path: "email",
        alias: "",
        name: "SignUpEmailForm",
        component: () => import("@/views/signUp/EmailForm.vue"),
        meta: {
          step: 1,
          layout: "FormLayout"
        }
      },
      {
        path: "date-of-birth",
        name: "SignUpBirthDateForm",
        component: () => import("@/views/signUp/BirthDateForm.vue"),
        meta: {
          step: 2,
          layout: "FormLayout"
        }
      },
      {
        path: "name",
        name: "SignUpNameForm",
        component: () => import("@/views/signUp/NameForm.vue"),
        meta: {
          step: 3,
          layout: "FormLayout"
        }
      },
      {
        path: "contact-preferences",
        name: "ContactPreferences",
        component: () => import("@/views/signUp/ContactPreferences.vue"),
        meta: {
          step: 4,
          layout: "FormLayout"
        }
      },
      {
        path: "create-password",
        name: "CreatePassword",
        component: () => import("@/views/signUp/CreatePassword.vue"),
        meta: {
          step: 5,
          layout: "FormLayout"
        }
      },
      {
        path: "email-sent",
        name: "CheckInbox",
        component: () => import("@/views/signUp/CheckInbox.vue"),
        meta: { layout: "FormLayout" }
      },
      {
        path: "confirm-email",
        name: "ConfirmEmail",
        component: () => import("@/views/signUp/ConfirmEmail.vue"),
        meta: {
          layout: "FormLayout",
          showLogoHeader: false,
          showBackButton: false
        }
      },
      {
        path: "enter-code",
        name: "EnterCode",
        component: () => import("@/views/signUp/EnterCode.vue"),
        meta: { layout: "FormLayout" }
      },
      {
        path: "request-code",
        name: "RequestCode",
        component: () => import("@/views/signUp/RequestCode.vue"),
        meta: { layout: "FormLayout" }
      },
      {
        path: "verify-code",
        name: "VerifyCode",
        component: () => import("@/views/signUp/RequestCodeVerify.vue"),
        meta: { layout: "FormLayout" }
      }
    ]
  },
  {
    path: "/recover-password-confirm",
    name: "RecoverPasswordConfirm",
    component: () => import("@/views/RecoverPassword.vue"),
    meta: {
      layout: "LayoutDefault"
    }
  },
  {
    path: "/recover-password",
    name: "RecoverPassword",
    component: () => import("@/views/RecoverPassword.vue"),
    meta: {
      layout: "FormLayout"
    }
  },
  {
    path: "/help-and-support",
    name: "HelpAndSupport",
    component: () => import("@/views/HelpAndSupport.vue"),
    meta: {
      layout: "LayoutLoggedEmpty",
      hideMenuMobile: true
    }
  },
  {
    path: "/report-user",
    name: "ReportUser",
    component: () => import("@/views/ReportUser.vue"),
    meta: {
      layout: "LayoutLoggedEmpty",
      hideMenuMobile: true
    },
    props: true
  },
  {
    path: "/newsletter/unsubscribe/:newsletterUnsubscribeId",
    name: "NewsletterUnsubscribe",
    component: () => import("@/views/NewsletterUnsubscribe.vue"),
    meta: {
      layout: "LayoutLoggedEmpty",
      hideMenuMobile: true
    },
    props: true
  },
  {
    path: "/404",
    name: "NotFound",
    component: () => import("@/views/NotFoundPage.vue"),
    meta: {
      layout: "LayoutDefault"
    }
  },
  {
    path: "*",
    name: "CampaignPage",
    component: () => import("@/views/CampaignPage.vue"),
    meta: {
      layout: "LayoutDefault"
    }
  }
];
export default routes;
