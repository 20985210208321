import { render, staticRenderFns } from "./_base-modal.vue?vue&type=template&id=7283a3f8&scoped=true&"
import script from "./_base-modal.vue?vue&type=script&lang=js&"
export * from "./_base-modal.vue?vue&type=script&lang=js&"
import style0 from "./_base-modal.vue?vue&type=style&index=0&id=7283a3f8&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7283a3f8",
  null
  
)

export default component.exports