import { Auth } from "@aws-amplify/auth";

export async function jwtTokenPayload() {
  try {
    return (await Auth.currentAuthenticatedUser()).signInUserSession?.idToken
      ?.payload;
  } catch (err) {
    return null;
  }
}
