<template>
  <div
    id="flex-modal"
    class="modal-container"
    tabindex="0"
    :class="{ one: initialized, out: closed }"
    @keydown.esc="closeModal()"
  >
    <div class="modal-background" @click="closeModal()" />
    <div class="modal-box">
      <!-- Modal content -->
      <div
        @click.prevent
        class="modal mx-auto fixed bottom-0 left-0 flex flex-col overflow-hidden"
        :class="[bgColor]"
        style="width: fit-content;"
      >
        <slot />
      </div>
    </div>
  </div>
</template>
<script>
import { findNextTabStop } from "@/utils/basicHelpers.js";
//todo => add prop with animation type, define different types of animation
export default {
  name: "FlexModal",
  props: {
    value: {
      type: Boolean,
      required: true
    },
    size: {
      type: String,
      required: false,
      default: "h-44 w-72"
    },
    bgColor: {
      type: String,
      required: false,
      default: "bg-gray-50"
    },
    confirmText: {
      type: String,
      required: false,
      default: ""
    },
    cancelText: {
      type: String,
      required: false,
      default: ""
    }
  },
  data() {
    return {
      closed: true,
      isOpen: false,
      initialized: false
    };
  },
  computed: {
    confirmDefaultMessage() {
      return this.$t("message.confirm");
    },
    cancelDefaultMessage() {
      return this.$t("message.cancel");
    }
  },
  watch: {
    value(val) {
      if (val) {
        this.closed = false;
        this.isOpen = true;
        this.initialized = true;
        const modal = document.getElementById("flex-modal");
        modal.focus();
      } else {
        if (this.isOpen) {
          this.closeModal();
        }
        const modal = document.getElementById("flex-modal");
        const newToFocus = findNextTabStop(modal);
        newToFocus.focus();
      }
    }
  },
  methods: {
    closeModal() {
      this.closed = true;
      setTimeout(() => {
        this.isOpen = false;
        this.$emit("input", false);
        this.initialized = false;
        this.closed = false;
      }, 150);
    }
  }
};
</script>
<style lang="postcss" scoped>
/* The Modal (background) */
.modal-container {
  position: fixed;
  display: table;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  transform: scale(0);
  z-index: inherit;

  .modal-background {
    position: absolute;
    background: rgb(0 0 0 / 40%);
    width: 100%;
    height: 100%;
  }

  .modal-box {
    display: table-cell;
    text-align: center;
    vertical-align: middle;

    .modal {
      background: white;
      border-radius: 14px;
      border: 1px solid;

      @apply border-gray-200;

      font-weight: 300;
      position: relative;

      h2 {
        font-size: 25px;
        line-height: 25px;
        margin-bottom: 15px;
      }

      p {
        font-size: 18px;
        line-height: 22px;
      }

      .modal-svg {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        border-radius: 3px;

        rect {
          stroke: #fff;
          stroke-width: 2px;
          stroke-dasharray: 778;
          stroke-dashoffset: 778;
        }
      }
    }
  }

  &:focus {
    border: none;
    outline: none;
  }

  &.one {
    transform: scaleY(1) scaleX(1);

    .modal-background {
      transform: scaleY(0.01) scaleX(0);
      animation: unfold-in 0s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    }

    .modal-box {
      .modal {
        transform: scale(0);
        animation: zoom-in 0.3s 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
      }
    }

    &.out {
      /*
        animation-duration: 0s;
        animation-delay: 1.3s;
        animation-iteration-count: 1;
        animation-direction: normal;
        animation-fill-mode: forwards;
        animation-play-state: running;
        animation-name: unfold-out-data-v-89e33f44;
      */
      animation: unfold-out 0s 1.3s forwards;

      .modal-background {
        transform: scale(1);
        animation: unfold-out 0s 0.3s cubic-bezier(0.165, 0.84, 0.44, 1)
          forwards;
      }

      .modal-box {
        .modal {
          animation: zoom-out 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
        }
      }
    }
  }
}

@keyframes unfold-in {
  0% {
    transform: scaleY(0.005) scaleX(0);
  }

  50% {
    transform: scaleY(0.005) scaleX(1);
  }

  100% {
    transform: scaleY(1) scaleX(1);
  }
}

@keyframes unfold-out {
  0% {
    transform: scaleY(1) scaleX(1);
  }

  50% {
    transform: scaleY(0.005) scaleX(1);
  }

  100% {
    transform: scaleY(0.005) scaleX(0);
  }
}

@keyframes zoom-in {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes zoom-out {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}
</style>
