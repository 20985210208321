<template>
  <button
    :class="[
      type,
      {
        'h-14 w-14 bg-gray-400 bg-opacity-60 rounded-full':
          !isDesktop && type == 'base'
      }
    ]"
    class="h-14 flex justify-center items-center arrow-box outline-none focus:outline-none"
    @click="$emit('click')"
  >
    <div class="arrow left" :class="{ 'ml-1': !isDesktop }"></div>
    <span v-if="text" class="ml-4 font-semibold">{{ text }}</span>
  </button>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "backButton",
  props: {
    type: {
      type: String,
      required: false,
      default: "base"
    },
    text: {
      type: String,
      required: false,
      default: ""
    }
  },
  computed: {
    ...mapGetters("general", ["isDesktop"])
  },
  methods: {
    clicked() {
      this.$emit("btnClick");
    }
  }
};
</script>
<style lang="postcss" scoped>
.base {
  &.arrow-box {
    .arrow {
      border: solid white;
      border-width: 0 4px 4px 0;
      display: inline-block;
      padding: 6px;
    }

    .left {
      transform: rotate(135deg);
    }
  }
}

.dark {
  &.arrow-box {
    .arrow {
      border: solid black;
      border-width: 0 4px 4px 0;
      display: inline-block;
      padding: 6px;
    }

    .left {
      transform: rotate(135deg);
    }
  }
}

.blue {
  &.arrow-box {
    .arrow {
      border: solid;
      border-width: 0 4px 4px 0;
      display: inline-block;
      padding: 6px;

      @apply border-blue-900;
    }

    .left {
      transform: rotate(135deg);
    }
  }
}
</style>
