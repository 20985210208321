<template>
  <div
    id="mini-menu"
    class="top-0 w-full items-center px-4"
    :class="[
      isDesktop ? 'bg-white py-4' : 'mt-12',
      { 'sticky no-logged-menu top-0': !isLogged }
    ]"
  >
    <div
      class="h-full relative flex flex-row items-center justify-center sm:justify-between mx-auto"
      :class="{
        'flex-col md:flex-row': !isLogged,
        'max-w-desktop': !fullScreen
      }"
    >
      <router-link :to="{ name: 'HomeIn' }">
        <Logo
          tabindex="0"
          color="pink"
          class="logo block w-32 cursor-pointer"
        />
      </router-link>
      <router-link :to="{ name: 'MyProfilePage' }" v-if="currentUser">
        <Avatar
          :displayName="currentUser.attributes.displayName"
          :profileImageUrl="currentUser.attributes.profileImageUrl"
          class="absolute sm:relative right-0 top-0"
        />
      </router-link>
      <LoginShortCuts
        id="shortcuts"
        class="mt-4 hidden md:grid"
        v-if="isFinishedLoadingUser && !isLogged"
      />
    </div>
  </div>
</template>
<script>
import Logo from "@/components/Logo.vue";
import Avatar from "./Avatar.vue";
import LoginShortCuts from "@/components/shared/LoginShortCuts.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    Logo,
    Avatar,
    LoginShortCuts
  },
  props: {
    fullScreen: {
      type: Boolean,
      required: false,
      default() {
        return false;
      }
    }
  },
  computed: {
    ...mapGetters("general", ["isDesktop"]),
    ...mapGetters("auth", ["currentUser", "isLogged", "isFinishedLoadingUser"]),
    routeInfo() {
      return this.$route;
    },
    currentlyOnHomeIn() {
      return this.$route.name === "HomeIn";
    },
    currentlyOnDiscoverPage() {
      return this.$route.name === "discoverPage";
    },
    currentlyOnInboxPage() {
      return this.$route.name === "Inbox";
    },
    currentlyOnProfilePage() {
      return (
        this.$route.name === "ProfilePage" ||
        this.$route.name === "MyProfilePage"
      );
    }
  }
};
</script>
<style lang="postcss">
#mini-menu {
  #shortcuts {
    max-width: 354px;
  }
}
.logo {
  svg {
    width: 100%;
  }
}

.menu-options {
  a {
    max-width: 120px;
    min-width: 80px;
  }
}
.no-logged-menu {
  z-index: 1;
}
</style>
