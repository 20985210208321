import Vue from "vue";
import Router from "vue-router";
import loggedPages from "./modules/logged";
import shared from "./modules/shared";
import store from "@/store";
import middlewarePipeline from "./middlewarePipeline";
import { clientVersion } from "@/utils/clientVersion.js";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    //based on the layout design I have to apply the scroll on the parent layout
    document.querySelector("#layout").scrollTo(0, 0);
    return { x: 0, y: 0 };
  },
  routes: [...loggedPages, ...shared]
});
router.beforeEach((to, from, next) => {
  router.referrer = from;
  if (!to.meta.middleware && !to.matched.some(m => m.meta.middleware)) {
    return next();
  } else {
    const middleware =
      to.meta.middleware ||
      to.matched.find(m => m.meta.middleware).meta.middleware;
    const context = {
      to,
      from,
      next,
      halt: next,
      store
    };
    return middleware[0]({
      ...context,
      next: middlewarePipeline(context, middleware, 1)
    });
  }
});
router.afterEach(to => {
  if (!router.referrer.name) return;

  Vue.prototype.$analytics.afterRouteChange(to);
});

// initial load - wait 2.5 seconds for initial load
setTimeout(() => {
  const analyticsUserId =
    store.getters["auth/currentUser"]?.attributes.analyticsUserId || null;
  Vue.prototype.$analytics.updateAnalyticsUserId(analyticsUserId);
  Vue.prototype.$analytics.afterRouteChange(router.history.current);
  Vue.prototype.$analytics.clientAppVersion(clientVersion());
}, 2500);

export default router;
